<template>
  <div>
    <div class="header">
      <img
        class="logo"
        height="35%"
        :src="require('@/assets/imgs/logo.png')"
      >
    </div>

    <Lang @changeLang="changeLang" />
    <div class="app-container">
      <StepContainer v-if="!policyDetail" :step="step" />

      <Category v-if="step === 0" v-model="form.productCategory" />
      <ReportBy v-if="step === 1" v-model="form" />
      <PolicyList v-if="step === 2" v-model="form" />
      <ReportInfo v-if="step === 3" v-model="form" />
    </div>

    <FuPopup
      v-model="showPopupV1"
      title=" "
      :close-on-click-overlay="false"
      :closeable="true"
    >
      <div class="claim-popup">

        <img
          class="img-chat"
          src="@/assets/imgs/icon_chat.png"
        >
        <p class="claim-pop-title">
          {{ $t('claim.claim_pop_title') }}
        </p>
        <p class="claim-pop-subtitle">
          {{ $t('claim.claim_pop_subtitle') }}
        </p>
        <div class="contract-info">
          <div>
            <p class="left-item">{{ $t('claim.claim_pop_phone') }}</p>
            <p class="colon">:</p>
            <p class="right-item">(021) 4080 0005</p>
          </div>

          <div class="info-div2">
            <p class="left-item">{{ $t('claim.claim_pop_whatsapp') }}</p>
            <p class="colon">:</p>
            <p class="right-item">+62 811 81 226 888</p>
          </div>

          <div>
            <p class="left-item">{{ $t('claim.claim_pop_emain') }}</p>
            <p class="colon">:</p>
            <p class="right-item">cs@fuse.co.id</p>
          </div>

        </div>

        <div class="service-info">
          <p class="service-info-title">{{ $t('claim.claim_pop_service_hours') }}</p>

          <div class="info-div3">
            <p class="left-item">{{ $t('claim.claim_pop_service_mf') }}</p>
            <p class="colon">:</p>
            <p class="right-item">09:00 - 18:00</p>
          </div>
          <div class="info-div4">
            <p class="left-item">{{ $t('claim.claim_pop_service_ss') }}</p>
            <p class="colon">:</p>
            <p class="right-item">{{ $t('claim.claim_pop_not_op') }}</p>
          </div>
          <div>
            <p class="left-item">{{ $t('claim.claim_pop_public_holidays') }}</p>
            <p class="colon">:</p>
            <p class="right-item">{{ $t('claim.claim_pop_not_op') }}</p>
          </div>

        </div>

        <div class="back-btn" @click="onBackHomeClick">
          {{ $t('claim.claim_pop_back') }}
        </div>
      </div>
    </FuPopup>
  </div>

</template>

<script>
import StepContainer from './components/step'
import Category from './components/category/index.vue'
import ReportBy from './components/reportBy/index.vue'
import PolicyList from './components/policyList/index.vue'
import ReportInfo from './components/reporterInfo/index.vue'
import CryptoJS from 'crypto-js'
import Dao from '@/utils/dao'
import Lang from '@/views/inspectionCustomer/component/lang/index.vue'
import FuPopup from '@/components/FuPopup'

export default {
  components: { StepContainer, Category, ReportBy, PolicyList, ReportInfo, Lang, FuPopup },
  props: {},
  data() {
    return {
      lang: this.$store.state.base.lang,
      routeQuery: {},
      reshow: false,
      form: {
        productCategory: '', // car
        reportBy: {
          // plateCode: 'BA',
          // plateNo: '136 CEK',
          // vehicleEngineNo: 'ENGINEMOBILCEKPREMI136',
          // vehicleVinNo: 'CHASSISMOBILCEKPREMI136'
        },
        slipUidList: [], // 1773243114199007233
        reportInfo: {
          reporter: '',
          reporterMobile: '',
          reporterEmail: ''
        }
      },
      step: 0,
      showPopupV1: false
    }
  },
  computed: {
    policyDetail() {
      return this.$route.query.slipUid
    }
  },
  provide() {
    return {
      reshow: () => { return this.reshow }
    }
  },
  watch: {
    step(newval, oldval) {
      if (oldval == 2 && newval == 1) {
        this.reshow = true
      } else {
        this.reshow = false
      }
    }
  },
  activated() {
    this.initParams()
    this.init()
  },
  methods: {
    initParams() {
      const { formNo, tenantId, tempVisitToken } = this.$route.query
      this.routeQuery = {
        formNo: this.decryptByAES(formNo),
        tenantId: this.decryptByAES(tenantId),
        tempVisitToken: this.decryptByAES(tempVisitToken)
      }
    },
    decryptByAES(cipherText, keyInBase64Str = '489E7BA7228D49589364C5C9EB54268E') {
      const key = CryptoJS.enc.Base64.parse(keyInBase64Str)
      // 返回的是一个Word Array Object，其实就是Java里的字节数组
      const decrypted = CryptoJS.AES.decrypt(cipherText, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      })

      return decrypted.toString(CryptoJS.enc.Utf8)
    },
    init() {
      this.showPopupV1 = true
      const { tempVisitToken } = this.routeQuery
      Dao.set('tempToken', tempVisitToken)
      if (!this.policyDetail) return
      this.step = 3
    },
    changeLang(val) {
      this.lang = val
    },

    onBackHomeClick() {
      this.showPopupV1 = false
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  padding: 10px 10px 80px;
}
.header {
  padding: 0 20px;
  height: 100px;
  line-height: 75px;
  background-color: #D90109;
  font-size: 0;
  text-align: center;
}

.claim-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000000;
}

.claim-pop-title {
  font-weight: 700;
  font-size: 16px;
  margin-top: 15px;
}

.claim-pop-subtitle {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  justify-content: center;
}

.contract-info,
.service-info {
  width: 100%;
  background-color: #F5F5F5;
  padding: 10px;
  margin: 10px 15px 0;
  border-radius: 8px;
}

.service-info-title {
  font-weight: 600;
  font-size: 12px;
}

.contract-info div,
.service-info div {
  font-weight: 600;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.left-item {
  flex: 1;
}

.right-item {
  flex: 1;
  text-align: right;
}

.info-div2, .info-div3{
  margin-top: 10px;
  margin-bottom: 10px;
}

.info-div4{
  margin-bottom: 10px;
}

.back-btn {
  font-weight: 700;
  font-size: 12px;
  width: 100%;
  text-align: center;
  margin: 10px 15px 0;
  padding: 12px 0;
  background-color: #F5F5F5;
  border: 1px solid #D3D3D3;
  border-radius: 8px;
  cursor: pointer;
}
</style>
